//
// A C T I O N S
//
//

export const TOGGLE_SIDEMENU = 'TOGGLE_SIDEMENU';

//
//
// A C T I O N S - C R E A T O R S
//
//

export const toggleSideMenu = () => ({
  type: TOGGLE_SIDEMENU
});
